<template>
    <ActivityDrawer v-model="visible">
        <ActivityItem v-if="reply" key="reply" @click="openResponseForm()">
            <i class="fi fi-rr-undo mr-2" />
            Ответить
        </ActivityItem>
        <ActivityItem v-if="user && user.id === item.author.id" key="edit" @click="openEdit()">
            <i class="fi fi-rr-edit mr-2" />
            Редактировать
        </ActivityItem>
        <ActivityItem v-if="openCheck" key="open" @click="openComment(item.id)">
            <i class="fi fi-rr-comment-alt-dots mr-2" />
            Открыть комментарий
        </ActivityItem>
        <ActivityItem v-if="addTaskCheck" key="task" @click="addTask()">
            <i class="fi fi-rr-list-check mr-2" />
            Добавить задачу
        </ActivityItem>
        <ActivityItem v-if="copyCheck" key="copy" @click="copyItem()">
            <i class="fi fi-rr-copy-alt mr-2" />
            Копировать
        </ActivityItem>
        <ActivityItem v-if="shareCheck" key="share" @click="share()">
            <i class="fi fi-rr-share mr-2" />
            Поделиться
        </ActivityItem>
        <ActivityItem v-if="user && user.id === item.author.id" key="delete" @click="removeHandler()">
            <div class="text-red-500 flex items-center">
                <i class="fi fi-rr-trash mr-2" />
                Удалить
            </div>
        </ActivityItem>
    </ActivityDrawer>
</template>

<script>
import { ActivityItem, ActivityDrawer } from '@/components/ActivitySelect'
export default {
    components: {
        ActivityItem, 
        ActivityDrawer
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        openResponseForm: {
            type: Function,
            default: () => {}
        },
        openEdit: {
            type: Function,
            default: () => {}
        },
        openComment: {
            type: Function,
            default: () => {}
        },
        addTask: {
            type: Function,
            default: () => {}
        },
        share: {
            type: Function,
            default: () => {}
        },
        removeHandler: {
            type: Function,
            default: () => {}
        },
        reply: {
            type: Boolean,
            default: true
        },
        user: {
            type: Object,
            required: true
        },
        openCheck: {
            type: Boolean,
            default: true
        },
        addTaskCheck: {
            type: Boolean,
            default: true
        },
        shareCheck: {
            type: Boolean,
            default: true
        },
        copyCheck: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            visible: false
        }
    },
    methods: {
        openDrawer() {
            this.visible = true
        },
        copyItem() {
            try {
                navigator.clipboard.writeText(this.item.text_clear)
                this.$message.info('Сообщение скопировано')
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка копирования')
            }
        }
    }
}
</script>